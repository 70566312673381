const ENG = {
	translation: {
		'menu-resume': 'Resume',
		'menu-portfolio': 'Portfolio',
		'menu-blog': 'Blog',
		'menu-contact': 'Contact',
		'intro-title': 'I am a developer who develops from a service point of view, opinion and convergence.',
		'intro-desc':
			'I am a style that develops quickly to requirements, meticulously tests and develops for accurate results, and actively adopts development using DevOps environments such as CI/CD to make these developments more efficient. In recent years, I have been interested in high-capacity data processing services in order to continue to grow as a back-end developer. In addition, I am studying RabbitMQ, Apache Spark, Spring Cloud, Spring Batch.',
		'name-title': 'Name',
		name: 'Kwang Soo Jeong',
		'birth-title': 'Date of birth',
		birth: 'August 25, 1993',
		'email-title': 'E-mail',
		'exp-year': ' year experience',
		'exp-years': ' years experience',
		'filter-all': 'All',
		'filter-desktop': 'Desktop App',
		'filter-mobile': 'Mobile App',
		'filter-web': 'Web App',
		'filter-ml': 'ML Model',
		'con-name-form': 'Name*',
		'con-email-form': 'E-mail*',
		'con-desc-form': 'Your Message',
		'con-name-error': 'Please enter a value with valid length',
		'con-email-error': 'Please enter a valid email address',
		'con-desc-error': 'Please enter a description',
		'con-loc-title': 'Location',
		'con-loc-content': 'Cheonan, Republic of KR',
		'con-email-title': 'E-mail',
		'con-send-btn': 'Send Message',
		'con-form-loading-title': 'Sending',
		'con-form-loading-body': 'Sending Please wait a moment.',
		'con-form-success-title': 'Success',
		'con-form-success-body': 'Your message has been sent.',
		'con-form-fail-title': 'Fail',
		'con-form-fail-body': 'Failed to send the message.',
		'con-form-error-title': 'Error',
		'con-form-error-body': 'Please write your inquiry correctly.',
		'con-form-ok-btn': 'OK',
	},
};

export default ENG;
