const KOR = {
	translation: {
		'menu-resume': '이력서',
		'menu-portfolio': '포트폴리오',
		'menu-blog': '블로그',
		'menu-contact': '문의',
		'intro-title': '서비스 관점에서 보고 의견과 수렴을 통해 발전하는 개발자',
		'intro-desc':
			'요구 사항에 맞춰 빠르게 개발하고 정확한 결과를 위해 꼼꼼히 테스트하며 개발하는 스타일이며 이러한 개발을 보다 능률적으로 진행하기 위해 CI/CD 등의 DevOps 환경을 사용한 개발을 적극 채택하고 있습니다. 최근에는 백엔드 개발자로써의 지속적인 성장을 위해 대용량 데이터 처리 서비스에 관심을 가지게 되었고, 이를 통해 Micro Service Architecture를 알게 되었습니다. 이와 더불어, RabbitMQ, Apache Spark, Spring Cloud, Spring Batch를 공부하고 있습니다.',
		'name-title': '이름',
		name: '정 광 수',
		'birth-title': '생년월일',
		birth: '1993. 08. 25',
		'email-title': '이메일 주소',
		'exp-year': '년 사용',
		'exp-years': '년 사용',
		'filter-all': '전체',
		'filter-desktop': '데스크톱 앱',
		'filter-mobile': '모바일 앱',
		'filter-web': '웹 앱',
		'filter-ml': '머신 러닝',
		'con-name-form': '이름*',
		'con-email-form': '이메일 주소*',
		'con-desc-form': '메시지를 입력해주세요.',
		'con-name-error': '이름을 입력해주세요.',
		'con-email-error': '올바른 이메일 주소를 입력해주세요.',
		'con-desc-error': '문의하실 내용을 입력해주세요.',
		'con-loc-title': '위치',
		'con-loc-content': '대한민국 천안시',
		'con-email-title': '이메일 주소',
		'con-send-btn': '메시지 전송',
		'con-form-loading-title': '전송 중',
		'con-form-loading-body': '전송 중입니다. 잠시만 기다려주십시오.',
		'con-form-success-title': '전송 완료',
		'con-form-success-body': '메시지가 전송되었습니다.',
		'con-form-fail-title': '전송 실패',
		'con-form-fail-body': '메시지를 보내는 데 실패하였습니다.',
		'con-form-ok-btn': '확인',
		'con-form-error-title': '오류',
		'con-form-error-body': '문의 사항을 올바르게 적어주십시오.',
	},
};

export default KOR;
