const JPN = {
	translation: {
		'menu-resume': '履歴書',
		'menu-portfolio': 'ポートフォリオ',
		'menu-blog': 'ブログ',
		'menu-contact': 'コンタクト',
		'intro-title': 'サービスの観点から見て意見や収斂を通じて発展する開発者',
		'intro-desc':
			'私の要件に合わせて迅速に開発して、正確な結果を得るため入念にテストして開発するスタイルであり、このような開発をより効率的に進めるためにCI/CD等のDevOps環境を使用した開発を積極的に採用しています。最近では、バックエンドの開発者としての持続的な成長のために大容量のデータ処理サービスに興味を持つようになり、これにより、Micro Service Architectureを知りました。これに加え、RabbitMQ、Apache Spark、Spring Cloud、Spring Batchを勉強しています。',
		'name-title': '名前',
		name: '鄭 光 洙',
		'birth-title': '誕生日',
		birth: '1993年 08月 25日',
		'email-title': 'メールアドレス',
		'exp-year': '年のキャリア',
		'exp-years': '年のキャリア',
		'filter-all': '全体',
		'filter-desktop': 'Desktop アプリ',
		'filter-mobile': 'Mobile アプリ',
		'filter-web': 'Web アプリ',
		'filter-ml': '人工知能',
		'con-name-form': '名前*',
		'con-email-form': 'メールアドレス*',
		'con-desc-form': 'メッセージを入力してください。',
		'con-name-error': '名前を入力してください。',
		'con-email-error': '有効なメールアドレスを入力してください。',
		'con-desc-error': '連絡することを入力してください。',
		'con-loc-title': '位置',
		'con-loc-content': '大韓民国 天安市',
		'con-email-title': 'メールアドレス',
		'con-send-btn': '転送',
		'con-form-loading-title': '転送中',
		'con-form-loading-body': '要求されたメッセージを転送しています。しばらくお待ちください。',
		'con-form-success-title': '転送完了',
		'con-form-success-body': 'メッセージが正常に送信されました。',
		'con-form-fail-title': '転送失敗',
		'con-form-fail-body': 'メッセージを転送するために失敗しました。',
		'con-form-error-title': 'エラー',
		'con-form-error-body': 'フォームを正しく記入してください。',
		'con-form-ok-btn': '確認',
	},
};

export default JPN;
